@font-face {
  font-family: 'Myriad Bold';
  src: url("../fonts/Myriad-Bold.eot");
  src: url("../fonts/Myriad-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Myriad-Bold.woff") format("woff"), url("../fonts/Myriad-Bold.ttf") format("truetype"), url("../fonts/Myriad-Bold.svg#CircularStd-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Myriad Pro Condensed';
  src: url("../fonts/Myriad-Pro-Condensed.eot");
  src: url("../fonts/Myriad-Pro-Condensed.eot?#iefix") format("embedded-opentype"), url("../fonts/Myriad-Pro-Condensed.woff") format("woff"), url("../fonts/Myriad-Pro-Condensed.ttf") format("truetype"), url("../fonts/Myriad-Pro-Condensed.svg#CircularStd-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'MyriadPro Regular';
  src: url("../fonts/Myriad-Pro-Regular.eot");
  src: url("../fonts/Myriad-Pro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Myriad-Pro-Regular.woff") format("woff"), url("../fonts/Myriad-Pro-Regular.ttf") format("truetype"), url("../fonts/Myriad-Pro-Regular.svg#CircularStd-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

body {
  background: url("../images/charcoal_gully_bg2.jpg") no-repeat center center fixed;
  font: 14px/20px Arial,sans-serif;
  color: #3c3638; }
  body.front #content {
    padding: 0; }

#main {
  max-width: 1000px;
  margin: 0 auto; }

#content {
  background: url("../images/content_back.png") repeat-y scroll left top transparent;
  display: block;
  float: right;
  font: 14px/20px Arial,sans-serif;
  margin: 15px 0 0 0;
  min-height: 100%;
  padding: 25px 40px;
  position: relative;
  width: 680px;
  z-index: 15; }

#page-title {
  font-family: "Myriad Pro Condensed", Arial, Helvetica, sans-serif;
  font-size: 32px;
  font-weight: normal;
  margin: -25px -30px 20px -30px;
  padding: 8px 70px 8px;
  text-transform: uppercase;
  color: #fff;
  text-align: right;
  border-bottom: 3px solid #c7c6c4;
  background: #3c3638;
  line-height: 1;
  position: relative; }
  #page-title:after {
    content: '';
    background: url("../images/gullyribbon.png") no-repeat scroll right top transparent;
    height: 66px;
    position: absolute;
    width: 49px;
    z-index: 1;
    top: 0;
    right: 10px; }

#sidebar-first .section {
  margin: 0;
  position: relative;
  background: url("../images/menu_back.png") repeat-y top;
  min-height: 200px;
  text-align: center; }
  #sidebar-first .section:after {
    content: '';
    background: url("../images/hide_menu_back.png") no-repeat 0 0;
    height: 115px;
    position: absolute;
    width: 240px;
    left: 0;
    top: 100%; }

#block-system-main-menu {
  width: 200px;
  margin: 0 auto; }
  #block-system-main-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0; }
    #block-system-main-menu .menu li {
      list-style: none;
      margin: 0;
      padding: 0; }
      #block-system-main-menu .menu li:last-child a {
        border-bottom: 1px solid #343434;
        margin-bottom: 10px; }
    #block-system-main-menu .menu a {
      display: block;
      font: 18px "Myriad Pro Condensed", Arial, Helvetica, sans-serif, "MyriadPro Regular", Arial, Helvetica, sans-serif, "Helvetica", "Verdana", sans-serif;
      line-height: 45px;
      outline: medium none;
      text-decoration: none;
      text-transform: uppercase;
      color: #ffffff;
      border-top: 1px solid #343434; }
      #block-system-main-menu .menu a.active, #block-system-main-menu .menu a:hover {
        background: url("../images/menu_hover_arrow.png") no-repeat right; }

#content h2 {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 7px;
  padding-bottom: 5px;
  border-bottom: 1px solid #bbb;
  color: #222;
  font-family: "Myriad Pro Condensed", Arial, Helvetica, sans-serif, "MyriadPro Regular", Arial, Helvetica, sans-serif, "Helvetica", "Verdana", sans-serif;
  font-weight: normal; }

#content ul, #content ol {
  margin: 0 1.5em 1.5em 0;
  padding-left: 1.5em; }
  #content ul li, #content ol li {
    margin-bottom: 8px; }

#content p {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 1.5em 0; }
  #content p img {
    background: #fff;
    padding: 20px; }
  #content p a {
    color: #95a02d; }
    #content p a:visited {
      color: #cf1313; }

.view-id-the_people .name {
  background: #222;
  padding: 5px;
  color: #fff;
  margin-bottom: 0.5em; }

.view-id-the_people .views-row {
  margin-bottom: 1.5em; }

.view-id-the_people .node-person {
  position: relative; }
  .view-id-the_people .node-person:after {
    content: '';
    display: table;
    clear: both; }

.view-id-the_people .field-name-field-person-image {
  width: 50%;
  float: left;
  margin-top: 10px; }

.view-id-the_people .field-name-body {
  width: 49.5%;
  float: left; }

.view-id-our_wines h3 {
  background: #222;
  padding: 5px;
  color: #fff;
  margin-bottom: 0.5em;
  font-weight: normal;
  clear: both; }

.view-id-our_wines .views-row {
  width: 50%;
  float: left;
  margin-bottom: 1.5em;
  text-align: center; }

.view-id-our_wines .views-field-title a {
  color: #cf1313;
  text-decoration: none; }
  .view-id-our_wines .views-field-title a:hover {
    color: #222; }

.node-wine.view-mode-full {
  position: relative; }
  .node-wine.view-mode-full:after {
    display: table;
    content: '';
    clear: both; }
  .node-wine.view-mode-full .field-label {
    font-size: 20px;
    line-height: 1;
    margin-bottom: 7px;
    margin-top: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid #bbb;
    color: #222;
    font-family: "Myriad Pro Condensed", Arial, Helvetica, sans-serif, "MyriadPro Regular", Arial, Helvetica, sans-serif, "Helvetica", "Verdana", sans-serif;
    font-weight: normal; }
  .node-wine.view-mode-full .group-left {
    width: 30%;
    float: left;
    text-align: center; }
    .node-wine.view-mode-full .group-left .field-name-field-field-wine-image {
      margin: 0 0.5em 1em 0; }
  .node-wine.view-mode-full .group-right {
    width: 70%;
    float: left; }

.view-id-imagery .view-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 1.5em; }
  .view-id-imagery .view-content img {
    padding: 8px;
    border: 1px solid #bbb;
    margin: 5px; }

.view-id-imagery .pager {
  margin-bottom: 0; }
  .view-id-imagery .pager li {
    background-image: none;
    display: inline;
    list-style-type: none;
    padding: 0.5em;
    margin-left: 0; }
  .view-id-imagery .pager a {
    text-decoration: none;
    color: #222; }

.view-id-news .field-name-title h2 {
  font-family: Arial,sans-serif !important;
  border: none !important;
  font-size: 16px; }

.view-id-news .field-name-title a {
  font-size: 16px;
  color: #cf1313;
  text-decoration: none; }
  .view-id-news .field-name-title a:hover {
    color: #222; }

.view-id-news .views-row {
  border-bottom: 1px solid #222;
  margin-bottom: 10px; }

#contact-site-form label {
  font-weight: bold; }

#contact-site-form input, #contact-site-form textarea {
  background: none repeat scroll 0 0 #1B1B1B;
  border: 1px solid #333333;
  -webkit-border-radius: 5px 5px 5px 5px;
          border-radius: 5px 5px 5px 5px;
  color: white;
  font: 12px "lucida grande",tahoma,verdana,arial,sans-serif;
  padding: 8px; }
